'use strict';

/**
 * @ngdoc function
 * @name exhibitorPortaluiApp.directive:navLink
 * @description
 * # Navigation Link
 * Directive of the exhibitorPortalApp
 */
(function (angular, window) {
  function navLink($rootScope) {
    'ngInject'

    // Usage:
    //     <nav-link state="event.resources.documents"><i class="fa fa-cloud-download-alt"> Documents</i></nav-link>
    const directive = {
      restrict: 'E',
      scope: {
        state: '@',
        isDropdown: '<',  // Default is false
      },
      templateUrl: (window.location.hostname === 'localhost' ? '/app/' : '') + 'views/navLink.html',
      controller: controller,
      controllerAs: 'navLink',
      link: link,
      bindToController: true,
      transclude: true,
      replace: true,
    };
    return directive;

    function controller() {
      'ngInject';

      const self = this;

      self.$onInit = () => {
        self.isDropdown = angular.isDefined(self.isDropdown) && self.isDropdown;
      };

      self.buildLinkClassObject = function () {
        return {
          active: isActiveLink()
        };
      };

      function isActiveLink() {
        let result = false;

        const { currentState } = $rootScope;
        if (currentState) {
          result = currentState.indexOf(self.state) >= 0;
        }

        return result;
      }
    }

    function link(scope, element) {
      const self = directive.controllerAs ? scope[directive.controllerAs] : scope;

      if (self.isDropdown) {
        removeHrefFromLink();
      }

      function removeHrefFromLink() {
        element.removeAttr("ui-sref");
        element.removeAttr("href");
      }
    }
  }

  angular.module('exhibitorPortalApp')
    .directive('navLink', navLink);
})(angular, window);
