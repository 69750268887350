'use strict';

/**
 * @ngdoc function
 * @name exhibitorPortalApp.controller:DocumentsCtrl
 * @description
 * # Documents Ctrl
 * Controller of the exhibitorPortalApp
 */

(function (angular, window, undefined) {
  const DocumentsCtrl = function ($filter, ExhibitorResources, AllResources, Search) {
    'ngInject'
    var self = this;

    self.search = Search.resources;

    var ordered = $filter('orderBy')(ExhibitorResources.concat(AllResources), 'Title');

    for (var i = 0; i < ordered.length; i++)
      ordered[i].DisplayOrder = i;

    self.baseResources = angular.copy(ordered);
    self.paginated = self.resources = angular.copy(ordered);

    self.resourceClicked = function (event, linkText, downloadUrl) {
      event.preventDefault();
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'DownloadResource',
        'DisplayName': linkText
      });

      window.open(downloadUrl, '_blank');
    };
  };

  angular.module('exhibitorPortalApp')
    .controller('DocumentsCtrl', DocumentsCtrl);
})(angular, window);
