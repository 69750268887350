import Player from '@vimeo/player';

'use strict';

/**
 * @ngdoc function
 * @name exhibitorPortalApp.controller:VideosCtl
 * @description
 * # Videos Ctrl
 * Controller of the exhibitorPortalApp
 */
(function (angular, window) {
  angular.module('exhibitorPortalApp')
    .controller('VideosCtrl', VideosController);

  function VideosController($scope) {
    'ngInject';

    var self = this;

    self.videoConfig = getVideoConfiguration();

    function getVideoConfiguration() {
      return [
        { title: 'Your Guide to the Maritz Lead Retrieval App', id: '1048205462', thumbnailImage: 'your-guide.png' },
        { title: 'Maritz Lead Generation', id: '1045759054', thumbnailImage: 'lead-generation.png' },
        { title: 'Optimizing Maritz Lead Retrieval', id: '1045762374', thumbnailImage: 'optimizing-lead-retrieval.png' },
        { title: 'Maritz Exhibitor Etiquette', id: '1045738840', thumbnailImage: 'exhibitor-etiquette.png' },
        { title: 'Maritz Return on Investment', id: '1045764696', thumbnailImage: 'return-on-investment.png' },
      ];
    }

    self.playVideo = (videoConfig) => {
      const { id } = videoConfig;

      const videoContainerId = `vimeo_video_${id}`;
      const videoOptions = getVimeoPlayerOptions(id);

      const player = new Player(videoContainerId, videoOptions);
      registerPlayerEventListeners(player, videoConfig);
    };

    function getVimeoPlayerOptions(videoId) {
      return {
        id: videoId,
        autopause: true,
        autoplay: true,
        play_button_position: 'center',
      };
    }

    function registerPlayerEventListeners(player, videoConfig) {
      onPlayerLoaded(player, videoConfig);
      onPlayerPlay(player);
      onPlayerEnded(player);
      onPlayerTimeUpdate(player);
    }

    function onPlayerLoaded(player, videoConfig) {
      player.on('loaded', (eventData) => {
        $scope.$apply(() => {
          videoConfig.loaded = true;
        });
        pushEventToGoogleTagManagerDataLayer(player, "video_load", eventData);
      });
    }

    function onPlayerPlay(player) {
      player.on('play', (eventData) => {
        pushEventToGoogleTagManagerDataLayer(player, "video_play", eventData)
      });
    }

    function onPlayerEnded(player) {
      player.on('ended', (eventData) => {
        pushEventToGoogleTagManagerDataLayer(player, "video_ended", eventData)
      });
    }

    function onPlayerTimeUpdate(player) {
      const progressPercentThresholds = [25, 50, 75, 100];

      const timestampsPushed = [];

      player.on('timeupdate', (eventData) => {
        progressPercentThresholds.forEach((progressThreshold) => {
          pushTimeUpdateWhenProgressIsReached(player, eventData, timestampsPushed, progressThreshold);
        });
      });
    }

    function pushTimeUpdateWhenProgressIsReached(player, eventData, timestampsPushed, progress) {
      const currentProgress = getVideoProgressAsWholeNumber(eventData.percent);

      const progressHasBeenReached = currentProgress >= progress;
      const updateEventHasNotBeenFired = timestampsPushed.indexOf(progress) === -1;

      if (progressHasBeenReached && updateEventHasNotBeenFired) {
        timestampsPushed.push(progress);

        const progressAsDecimal = progress / 100;
        eventData.percent = progressAsDecimal;

        pushEventToGoogleTagManagerDataLayer(player, "video_timeupdate", eventData);
      }
    }

    function getVideoProgressAsWholeNumber(percentAsDecimal) {
      const percentShifted = percentAsDecimal * 100;
      return Math.floor(percentShifted);
    }

    function pushEventToGoogleTagManagerDataLayer(player, eventName, eventData) {
      if (typeof dataLayer === 'undefined') {
        return;
      }

      getPlayerInformation(player, eventData).then((result) => {
        const data = assembleGTMEventObject(result, eventName, eventData);
        dataLayer.push(data);
      });
    }

    function getPlayerInformation(player, eventData) {
      const promises = [player.getVideoUrl(), player.getVideoTitle()];

      let videoIdPromise;
      const doesNotHaveIdInEventData = eventData.id === undefined;

      if (doesNotHaveIdInEventData) {
        videoIdPromise = player.getVideoId();
      } else {
        videoIdPromise = Promise.resolve(eventData.id);
      }

      promises.push(videoIdPromise);
      return Promise.all(promises);
    }

    const VIDEO_PROVIDER = "Vimeo";
    function assembleGTMEventObject(playerInfoResult, eventName, eventData) {
      const [url, title, id] = playerInfoResult;

      const data = {
        event: eventName,
        video_provider: VIDEO_PROVIDER,
        video_url: url,
        video_title: title,
        video_id: id,
      };

      if (eventData.seconds !== undefined) {
        data['video_current_time'] = eventData.seconds;
      }
      if (eventData.duration !== undefined) {
        data['video_duration'] = eventData.duration;
      }
      if (eventData.percent !== undefined) {
        data['video_percent'] = getVideoProgressAsWholeNumber(eventData.percent);
      }

      return data;
    }
  }
})(angular, window);
