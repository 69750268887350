'use strict';

/**
 * @ngdoc function
 * @name exhibitorPortalApp.controller:NavCtrl
 * @description
 * # NavCtrl
 * Controller of the exhibitorPortalApp
 */

(function(angular, window, undefined) {
  var NavCtrl = function ($state, $rootScope, $window, ShowService, ngCart, ActiveUserProfile, EnvironmentConfig, OrderService) {
    'ngInject'
		var self = this;

		self.root = $rootScope;

		self.inEvent = function() {
			return !!ShowService.data.currentShow;
    };

		self.hasActivations = function() {
			return ShowService.data.currentShow && ShowService.data.activations && ShowService.data.activations[ShowService.data.currentShow.ShowCode];
		};

		self.hasCampaign = function () {
			if(ShowService.data.currentShow && ShowService.data.currentShow.ShowCode && OrderService.data.orders && OrderService.data.orders[ShowService.data.currentShow.ShowCode]){
				var orders = OrderService.data.orders[ShowService.data.currentShow.ShowCode];

		    	for(var i = 0; i < orders.length; i++){
					for(var a = 0; a < orders[i].Transactions.length; a++){
						if(orders[i].Transactions[a].DataSalesListId && orders[i].Transactions[a].TransTypeCode === 'PUR' && orders[i].Transactions[a].TransStatus !== 'VOIDED')
							return true;
					}
				}
			}
		};

		self.hasCollateral = function () {
			if(ShowService.data.currentShow && ShowService.data.currentShow.ShowCode && OrderService.data.orders && OrderService.data.orders[ShowService.data.currentShow.ShowCode]){
				var orders = OrderService.data.orders[ShowService.data.currentShow.ShowCode];
				var codes = ['311', '312', '313', '314']; //SWAP product codes

		    	for(var i = 0; i < orders.length; i++){
					for(var a = 0; a < orders[i].Transactions.length; a++){
						if(codes.indexOf(orders[i].Transactions[a].ProductCode) > -1 && orders[i].Transactions[a].TransTypeCode === 'PUR' && orders[i].Transactions[a].TransStatus !== 'VOIDED')
							return true;
					}
				}
			}
		};

		self.shop = function() {
        $state.go('event.shop.products');
		};

		self.logout = function () {
			window.location = EnvironmentConfig.SiteRoot + 'home/logout' + (ActiveUserProfile.SWAPOnly ? '/?swap=true' : '');
		};
	};

	angular.module('exhibitorPortalApp')
	  .controller('NavCtrl', NavCtrl);
})(angular, window);
